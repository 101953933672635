
            @import 'src/styles/mixins.scss';
        
.mainContainer {
    position: relative;
    margin-top: 90px;
    @include lg {
        margin-top: 185px;
    }
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: -2;
        background-color: #12366d;
    }
}
